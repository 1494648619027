<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Sales" title="주문내역" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Manage order</h5>-->
<!--            </div>-->

            <div class="card-body">
              <b-row>
                <b-col xl="3" lg="4" md="6">
                  <b-form-group
                    label-cols="3"
                    label="목록수"
                    class="datatable-select"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="offset-xl-6 offset-lg-2 search-rs" xl="3" lg="5" md="6">
                  <b-form-group
                    label-cols="3"
                    label="검색:"
                    class="datatable-select"
                  >
                    <b-form-input
                      type="text"
                      v-model="filter"
                      placeholder="검색어"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pb-2">
                조회 기간 :
                <date-picker v-model="search.start_date" format="YYYY-MM-DD" type="date" valueType="format" />
                ~
                <date-picker :value="search.end_date" format="YYYY-MM-DD" type="date" valueType="format" />
                <b-button class="btn ml-2" @click="searchOrder">조회</b-button>
              </div>

              <div class="table-responsive datatable-vue text-center">
                <b-table
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="orderList"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                  <template #empty="scope">
                    조회된 데이터가 없습니다.
                  </template>
                  <template #cell(goods_name)="field">
                    <a href="javascript:;" @click="selectOrder(field.item)">{{ field.item.goods_name }}</a>
                  </template>
                  <template #cell(pay_amount)="field">
                    {{ field.item.pay_amount | makeComma }}
                  </template>
<!--                  <template #cell(images)="field" class="d-flex">-->
<!--                    <img-->
<!--                      height="50px"-->
<!--                      :src="getImgUrl(field.item.images)"-->
<!--                      width="50px"-->
<!--                    />-->
<!--                  </template>-->
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
              <div class="row mt-3" v-if="!isAgent">
                <div class="col text-right"><b-button class="d-inline-flex" @click="excelDown">엑셀다운</b-button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import nodeApi from "@/api/nodeApi";
import Xlsx from "xlsx";

export default {
  comments: {DatePicker},
  data() {
    return {
      value: "",
      search: {
        start_date: '',
        end_date: ''
      },
      tablefields: [
        { key: "order_code", label: "주문번호", sortable: true },
        { key: "goods_name", label: "상품명", sortable: false },
        { key: "option_name", label: "옵션명", sortable: false },
        { key: "order_qty", label: "수량", sortable: false },
        { key: "pay_type_name", label: "결제방법", sortable: true },
        { key: "pay_amount", label: "결제금액", sortable: false },
        { key: "order_name", label: "주문인", sortable: false },
        { key: "receiver", label: "계약자", sortable: true },
        // { key: "receiver_phone", label: "전화번호", sortable: true },
        { key: "receiver_phone", label: "휴대번호", sortable: false },
        // { key: "receiver_zipcode", label: "우편번호", sortable: false },
        { key: "receiver_address", label: "주소", sortable: false },
        { key: "receiver_address_etc", label: "상세주소", sortable: false },
        { key: "order_status_name", label: "배송현황", sortable: true },
        { key: "reg_date", label: "주문일자", sortable: true }
      ],
      orders: [],
      orderList: [],
      orderExcelList: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100]
      , start_date: null
      , end_date : null
      , isAgent: false
    };
  },
  asyncData() {
  },
  created() {
    // this.$store.dispatch("order/getOrders");

    const user = JSON.parse(localStorage.getItem('userinfo'));

    if (user.user_type === 'USER_TYPE10' || user.user_type === 'USER_TYPE05') {
      this.isAgent = true;
    }

  },
  computed: {
    ...mapGetters({
      // orderList: "rental/getRentalList",
      // orderExcelList: "rental/getRentalExcelList"
      // orders: "order/getOrders"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.orders.length;
    let toDay = new Date();
    let preDay = new Date();
    preDay.setDate(toDay.getDate() - 30);


    this.$log.debug(['today', toDay, toDay.getFullYear(), toDay.getMonth(), toDay.getDate()]);
    this.$log.debug(['preDay', preDay, preDay.getFullYear(), preDay.getMonth(), preDay.getDate()]);

    let preStrDate = preDay.getFullYear() + '-' + ((preDay.getMonth() + 1) > 9 ? (preDay.getMonth() + 1) : '0' + (preDay.getMonth() + 1)) + '-' + (preDay.getDate() > 9 ? preDay.getDate() : '0' + preDay.getDate());
    this.$log.debug(['preStrDate', preStrDate]);
    let preEndDate = toDay.getFullYear() + '-' + ((toDay.getMonth() + 1) > 9 ? (toDay.getMonth() + 1) : '0' + (toDay.getMonth() + 1)) + '-' + (toDay.getDate() > 9 ? toDay.getDate() : '0' + toDay.getDate());
    this.$log.debug(['preEndDate', preEndDate]);
    this.search.start_date = preStrDate;
    this.search.end_date = preEndDate;

    this.searchOrder();
  },
  methods: {
    searchOrder() {
      let param = {
        start_date: this.search.start_date,
        end_date: this.search.end_date
      }
      nodeApi.postOrderList(param).then( res => {
        if (res.data.success) {
          this.orderList = res.data.order_list;
          this.orderExcelList = res.data.order_excel_list;
          this.totalRows = this.orderList.length;
          // this.orderExcelList = res.data.order_excel_list;
        }
      });
    },
    excelDown() {
      this.$log.debug(['excelDown']);
      const Heading = [
        ["주문번호", "상품명", "옵션명", "수량", "결제금액", "결제방법"
          , "주문자", "계약자", "연락처", "우편번호", "주소", "상세주소"
          , "카드사", "카드번호", "승인번호", "배송현황"
          , "취소사유", "택배사", "송장번호", "주문일", "취소일"]
      ]
      const workBook = Xlsx.utils.book_new()
      // const workHeader = Xlsx.utils.sheet_add_aoa(workBook, Heading);
      // Xlsx.utils.book_append_sheet(workBook, workHeader, 'header')
      // const workSheet = Xlsx.utils.sheet_add_json(workBook, this.rentalExcelList, {origin: 'A2', skipHeader: true});

      // this.rentalExcelList.unshift(Heading);
      const workSheet = Xlsx.utils.json_to_sheet(this.orderExcelList, {origin: 'A2', skipHeader: true});


      Xlsx.utils.sheet_add_aoa(workSheet, Heading);
      // const workSheet = Xlsx.utils.shh(this.rentalExcelList, heading);
      // Xlsx.utils.sheet_add_json(workBook, this.rentalExcelList, {origin: 'A2', skipHeader: true})

      Xlsx.utils.book_append_sheet(workBook, workSheet);
      Xlsx.writeFile(workBook, '주문목록.xlsx')
    },
    selectOrder(item) {
      this.$log.debug(['item', item])
      this.$router.push({name: 'orderView', params: { item: item }, props: true});
    },
    getImgUrl(path) {
      return require("@/assets/images/dashboard/product/" + path);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>

</style>
