// import axios from 'axios';
// import order from '../../data/order.json';

// import config from '../../../config.json'
// const ax = axios.create({
//     baseURL: config.baseUrl,
// });
// const url = 'order.json';
// const transactionUrl = 'transactions.json';

import nodeApi from "@/api/nodeApi";
import Vue from "vue";

const state = {
    orders: [],
    orderList: [],
    orderExcelList: [],
    transactions: []
}
const getters = {
    getOrderList(state) {
        return state.orderList;
    },
    getOrderExcelList(state) {
        return state.orderExcelList;
    },
    getOrders(state) {
        return state.orders;
    },
    getOrdersDashboard(state) {
        return state.orderList;
    },
    getTransactions(state) {
        return state.transactions;
    }
}
const actions = {
    getOrderList(context) {
        nodeApi.postOrderList({}).then( res => {
            Vue.$log.debug(['postOrderList', res]);
            if (res.data.success) {
                context.commit('getOrderList', res.data.rental_list)
            }
        }).catch( err => {
            Vue.$log.error(['get rental list error', err])
        });
    },
    orderExcelList(context) {
        nodeApi.postOrderExcelList({}).then( res => {
            if (res.data.success) {
                context.commit('rentalExcelList', res.data.rental_list)
            }
        }).catch( err => {
            Vue.$log.error(['excel down error', err])
        });
    },
    getOrders: (context) => {
        ax.get(url)
            .then(response => {
                context.commit('getOrders', response.data);
            })
            .catch(error => {
                console.log('error', error);
            })
    },

    getTransactions: (context) => {
        ax.get(transactionUrl)
            .then(response => {
                context.commit('getTransactions', response.data);
            })
            .catch(error => {
                console.log('error', error);
            })
    }
}
const mutations = {
    getOrderList(state, payload) {
        state.orderList = payload;
    },
    orderExcelList(state, payload) {
        state.orderExcelList = payload;
    },
    getOrders(state, payload) {
        state.orders = payload.data
    },
    getTransactions(state, payload) {
        state.transactions = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
