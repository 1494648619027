// import axios from 'axios';
import config from '../../../config.json'
import Vue from "vue";
import nodeApi from "@/api/nodeApi";
// const ax = axios.create({
//     baseURL: config.baseUrl,
// });
// const url = 'user.json';

const state = {
    rentalList: [],
    rentalExcelList: []
}
const getters = {
    getRentalList(state) {
        return state.rentalList;
    },
    getRentalExcelList(state) {
        return state.rentalExcelList;
    }
}
const actions = {
    getRentalList(context) {
        nodeApi.postRentalList({}).then( res => {
            Vue.$log.debug(['postRentalList', res]);
            if (res.data.success) {
                context.commit('getRentalList', res.data.rental_list)
            }
        }).catch( err => {
            Vue.$log.error(['get rental list error', err])
        });
    },
    rentalExcelList(context) {
        nodeApi.postRentalExcelList({}).then( res => {
            if (res.data.success) {
                context.commit('rentalExcelList', res.data.rental_list)
            }
        }).catch( err => {
            Vue.$log.error(['excel down error', err])
        });
    }
}
const mutations = {
    getRentalList(state, payload) {
        state.rentalList = payload;
    },
    rentalExcelList(state, payload) {
        state.rentalExcelList = payload;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
