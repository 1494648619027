<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 footer-copyright">
          <p class="mb-0">Copyright 2021 © 에코피아 All rights reserved.</p>
          <p class="mb-0">TEL : <a href="tel:031-856-9980">031-856-9980</a> A/S : <a href="tel:1522-1879">1522-1879</a></p>
        </div>
        <div class="col-md-6">
<!--          <p class="pull-right mb-0">-->
<!--            Hand crafted & made with <i class="fa fa-heart"></i>-->
<!--          </p>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>

</style>
