<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="사용자관리" :title="'사용자'+titleType" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
<!--        <div class="card-header">-->
<!--          <h5>Add User</h5>-->
<!--        </div>-->
        <div class="card-body">
          <ul class="nav nav-tabs tab-coupon" id="top-tab" role="tablist">
            <div class="tab-content" id="top-tabContent">
              <form class="needs-validation user-add" novalidate="">
<!--                <h4>Account Details</h4>-->
                <div class="form-group row">
                  <label for="validationCustom0" class="col-xl-3 col-md-4"
                  ><span>*</span> 아이디</label
                  >
                  <input
                      class="form-control col-xl-8 col-md-7"
                      id="validationCustom0"
                      type="text"
                      required=""
                      v-model="user.user_id"
                      :readonly="users"
                  />
                </div>
                <div class="form-group row" v-if="!isEdit">
                  <label for="validationCustom3" class="col-xl-3 col-md-4"
                    ><span>*</span> 비밀번호</label
                  >
                  <input
                    class="form-control col-xl-8 col-md-7"
                    id="validationCustom3"
                    type="password"
                    required=""
                    v-model="user.password"
                  />
                </div>
<!--                <div class="form-group row" v-if="!isEdit">-->
<!--                  <label for="validationCustom4" class="col-xl-3 col-md-4"-->
<!--                    ><span>*</span> Confirm Password</label-->
<!--                  >-->
<!--                  <input-->
<!--                    class="form-control col-xl-8 col-md-7"-->
<!--                    id="validationCustom4"-->
<!--                    type="password"-->
<!--                    required=""-->
<!--                  />-->
<!--                </div>-->
                <div class="form-group row">
                  <label for="validationCustom1" class="col-xl-3 col-md-4"
                  ><span>*</span> 이름</label
                  >
                  <input
                      class="form-control col-xl-8 col-md-7"
                      id="validationCustom1"
                      type="text"
                      required=""
                      v-model="user.name"
                  />
                </div>
                <div class="form-group row">
                  <label for="validationCustom2" class="col-xl-3 col-md-4"
                  ><span>*</span> 연락처</label
                  >
                  <input
                      class="form-control col-xl-8 col-md-7"
                      id="validationCustom2"
                      type="text"
                      required=""
                      v-model="user.phone"
                  />
                </div>
                <div class="form-group row">
                  <label for="fee_rate" class="col-xl-3 col-md-4"
                  ><span>*</span> 수수료율</label
                  >
                  <input
                      class="form-control col-xl-8 col-md-7"
                      id="fee_rate"
                      type="text"
                      required=""
                      :readonly="isAgent"
                      v-model="user.fee_rate"
                  />
                </div>
                <div class="form-group row">
                  <label for="validationCustom3" class="col-xl-3 col-md-4"
                  ><span>*</span> 상태</label
                  >
                  <b-select id="user_state" class="form-control col-xl-8 col-md-7" v-model="user.user_state" :options="options" >
<!--                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>-->
<!--                    <b-form-select-option value="">회원</b-form-select-option>-->
<!--                    <b-form-select-option value="">탈퇴</b-form-select-option>-->
                  </b-select>
<!--                  <input-->
<!--                      class="form-control col-xl-8 col-md-7"-->
<!--                      id="validationCustom3"-->
<!--                      type="password"-->
<!--                      required=""-->
<!--                  />-->
                </div>
                <div class="form-group row">
                  <label for="user_type" class="col-xl-3 col-md-4"
                  ><span>*</span> 사용자구분</label
                  >
<!--                  <span v-if="user.str_user_type">{{user.str_user_type}}</span>-->
                  <b-select id="user_type" :disabled="isAgent" v-model="user.user_type" :options="typeOptions" class="form-control col-xl-8 col-md-7"></b-select>
                </div>
              </form>

            </div>
          </ul>
          <div class="pull-right">
            <button type="button" class="btn btn-primary" @click="saveUser()">저장</button>
            <router-link to="/users/user-list"><button type="button" class="btn btn-dark ml-2">목록</button></router-link>
          </div>
        </div>

      </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import nodeApi from "@/api/nodeApi";

export default {
  data() {
    return {
      content: "<h1>Some initial content</h1>",
      selected: null,
      options: [
        {value: 'waiting', text: '대기중'},
        {value: 'ing', text: '사용'},
        {value: 'withdrawal', text: '탈퇴'}
      ],
      typeSelected: null,
      typeOptions: [
        {value: 'USER_TYPE10', text: '에이전트'},
        {value: 'USER_TYPE05', text: '서브 에이전트'},
        {value: 'USER_TYPE02', text: '일반사용자'}
      ],
      isEdit: false,
      isAgent: false,
      user: {
        user_code: '',
        user_id: null,
        password: null,
        name: null,
        phone: null,
        user_state: null,
        str_user_state: null,
        user_type: null,
        str_user_type: ''
      },
      titleType: '등록'
    };
  },
  props: ['users'],
  created() {
    if (this.users) {
      this.titleType = '수정';
      this.isEdit = true;
      this.user = this.users;
    }

    const user = JSON.parse(localStorage.getItem('userinfo'));

    if (user.user_type === 'USER_TYPE10' || user.user_type === 'USER_TYPE05') {
      this.isAgent = true;
    }

    // if (this.users.user_type === 'ing') {
    //   this.selected = {value: 'ing', text: '사용'};
    // }

  },
  methods: {
    onComplete() {
    },
    saveUser() {

      if (this.isEdit) {
        // 사용자 수정

        nodeApi.putUser(this.user).then( res => {
          if (res.data.success) {
            nodeApi.showToast(res.data.msg, "success", 1500);

            this.$router.push('/users/user-list');
          } else {
            nodeApi.showToast(res.data.msg);
          }
        }).catch( err => {
          nodeApi.showToast(err.toString());
        })
      } else {
        // 사용자 등록
        nodeApi.postUser(this.user).then( res => {
          if (res.data.success) {
            nodeApi.showToast(res.data.msg, "success", 1500);

            this.$router.push('/users/user-list');
          } else {
            nodeApi.showToast(res.data.msg);
          }
        }).catch( err => {
          nodeApi.showToast(err.toString());
        })
      }
    }
  }
};
</script>
<style scoped>

</style>
