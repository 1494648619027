<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="사용자관리" title="사용자목록" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>User Detail</h5>-->
<!--            </div>-->
            <div class="card-body">
              <router-link to="/users/create-user" v-if="!isAgent">
                <b-button variant="info" class="btn-popup pull-right"
                  >사용자 등록</b-button
                ></router-link
              >
              <b-row>
                <b-col xl="3" lg="4" md="6">
                  <b-form-group
                      label-cols="3"
                      label="목록수"
                      class="datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="offset-xl-6 offset-lg-2 search-rs" xl="3" lg="5" md="6">
                  <b-form-group
                      label-cols="3"
                      label="검색:"
                      class="datatable-select"
                  >
                    <b-form-input
                        type="text"
                        v-model="filter"
                        placeholder="검색어"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="table-responsive datatable-vue user-list">
                <div>
                  <b-modal
                    id="modal-1"
                    title="Confirmation"
                    @ok="deleteBatchRow"
                  >
                    <p class="my-4">Are you sure!</p>
                  </b-modal>
                </div>
                <div></div>
                <!--<commonTable
                  :selectMode="selectMode"
                  :items="items"
                  :fields="tablefields"
                  :filter="filter"
                  :perPage="perPage"
                  :totalRows="totalRows"
                  :currentPage="currentPage"
                  :selectable="false"
                >
                </commonTable>-->
                <b-table
                  class="text-center"
                  :select-mode="selectMode"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="items"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                  @row-clicked="selectUser"
                  @row-selected="rowSelected"
                >
<!--                  <template v-slot:head(delete)>-->
<!--                    <b-button-->
<!--                      variant="danger"-->
<!--                      :disabled="selectedRows.length === 0"-->
<!--                      @click="showMsgBoxTwo"-->
<!--                      >Delete</b-button-->
<!--                    >-->
<!--                  </template>-->
                  <template #empty="scope">
                    조회된 데이터가 없습니다.
                  </template>
                  <template #cell(image)="field">
                    <img
                      height="50px"
                      :src="getImgUrl(field.item.images)"
                      width="50px"
                    />
                  </template>
                  <template #cell(status) v-for="(item, index) in items">
                    <feather
                      v-if="item.status == true"
                      :key="index"
                      type="circle"
                      stroke="#81BA01"
                      stroke-width="1"
                      size="12px"
                      :fill="
                        item.status == true
                          ? '#81BA01'
                          : item.status == false
                          ? '#FBBC58'
                          : ''
                      "
                      stroke-linejoin="round"
                    ></feather>
                  </template>
                  <b-table-column v-slot:cell(delete)>
                    <a href="#">edit</a>
                    <a href="#"> remove</a>
                  </b-table-column>

                  <template v-slot:cell(delete)="{ item, field: { key } }">
                    <b-checkbox
                      v-model="item[key]"
                      @change="deleteSelected(item)"
                    ></b-checkbox>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import nodeApi from "@/api/nodeApi";

export default {
  data() {
    return {
      modes: ["multi", "single", "range"],
      tablefields: [
        // { key: "delete" },
        { key: "str_user_type", label: "사용자구분", sortable: true },
        { key: "user_id", label: "아이디", sortable: true },
        { key: "name", label: "이름", sortable: true },
        { key: "phone", label: "연락처", sortable: false },
        { key: "fee_rate", label: "수수료율", sortable: false },
        { key: "str_user_state", label: "상태", sortable: true },
        { key: "agent_name", label: "소속에이전트", sortable: true },
        { key: "reg_date", label: "가입일", sortable: true }

        // { key: "role", label: "Role", sortable: false }
      ],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selectMode: "multi",
      selected: [],
      isAgent: false
    };
  },
  created() {
    // this.$store.dispatch("user/getUsers");
    this.$store.dispatch("user/getUsers");

    const user = JSON.parse(localStorage.getItem('userinfo'));

    if (user.user_type === 'USER_TYPE10' || user.user_type === 'USER_TYPE05') {
      this.isAgent = true;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  computed: {
    ...mapGetters({
      items: "user/getUsers"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    selectedRows() {
      return this.items.filter(item => item.delete);
    }
  },
  methods: {
    deleteSelected(item) {
      let objIndex = this.selected.findIndex((obj => obj.id == item.id));
      if (objIndex > -1) {
        this.selected.splice(objIndex, 1);
      } else {
        this.selected.push(item)
      }
    },
    getImgUrl(path) {
      return require("@/assets/images/users/" + path);
    },
    rowSelected(item) {
      this.selected = item;
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteRow() {
      for( var i=this.items.length - 1; i>=0; i--){
        for( var j=0; j<this.selected.length; j++){
          if(this.items[i] && (this.items[i].id === this.selected[j].id)){
            this.items.splice(i, 1);
          }
        }
      }
    },
    deleteBatchRow() {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.items[i].id == this.selected[i].id) {
          this.items.splice(this.items[i], 1);
        }
      }
    },
    showMsgBoxTwo() {
        this.$bvModal.msgBoxConfirm('Are you sure!', {
          title: 'Confirmation',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'CANCLE',
          footerClass: 'p-2',
          hideHeaderClose: false,
          // centered: true
        })
          .then(value => {
            this.deleteRow();
            this.selected = [];
          })
          .catch(err => {
            // An error occurred
          })
      },
      selectUser(item) {
        this.$router.push({name: 'create-user', params: { users: item }, props: true});

      }
  }
};
</script>
