import Vue from "vue";
import axios from "axios";
import userAuth from "../auth/index.js";
const accessCookieTime = userAuth.accessCookieTime;

axios.interceptors.request.use((config) => {

    config.headers.Authorization = 'Bearer ' + Vue.$cookies.get('adminToken');
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    Vue.$log.debug(['nodeApi interceptor', response.headers['x-auth-token']]);

    // token 시간을 증가시켜준다?
    const accessToken = response.headers['x-auth-token'];
    if (accessToken) {
        Vue.$log.debug(['refresh token', accessToken]);
        Vue.$cookies.set('adminToken', accessToken, accessCookieTime);
    }

    return response;
}, (error) => {
    return Promise.reject(error);
})

let API_URL = 'https://api.orin.co.kr';
let IMG_URL = API_URL;
if (process.env.NODE_ENV === 'development') {
    API_URL = '';
    IMG_URL = 'http://localhost:3008';
}

let authBearer = null;

// console.debug(['nodeApi authCheck', userAuth.isAuthenticated()])

// if (userAuth.isAuthenticated()) {
//     authBearer = localStorage.getItem('userToken');
// }

// let header = {
//     'Content-Type': 'application/json',
//     'Authorization': 'Bearer ' + authBearer
// }

let getApiHeader = function () {
    let authBearer = null;
    if (userAuth.isAuthenticated()) {
        authBearer = localStorage.getItem('adminToken');
    }

    let header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBearer
    }

    Vue.$log.debug(['getApiHeader', header]);
    return header;
}


export default {
    showToast(msg, type, duration) {
        Vue.toasted.show(msg, {
            theme: "bubble",
            position: "top-center",
            type: type ? type : 'error',
            duration: duration ? duration : 2000
        });
    },
    imgUrl() {
        return IMG_URL;
    },
    postLogin(param) {
        return axios.post(API_URL + '/admin/login', param, {headers : getApiHeader()});
    },
    postUserList(param) {
        return axios.post(API_URL + '/admin/userList', param, {headers : getApiHeader()});
    },
    putUser(param) {
        return axios.put(API_URL + '/admin/user', param, {headers : getApiHeader()});
    },
    postUser(param) {
        return axios.post(API_URL + '/admin/user', param, {headers : getApiHeader()});
    },
    postRentalList(param) {
        return axios.post(API_URL + '/admin/rentalList', param, {headers : getApiHeader()});
    },
    postRentalExcelList(param) {
        return axios.post(API_URL + '/admin/rentalExcelList', param, {headers : getApiHeader()});
    },
    putRental(param) {
        return axios.put(API_URL + '/admin/rental', param, {headers : getApiHeader()});
    },
    putRentals(param) {
        return axios.put(API_URL + '/admin/rentals', param, {headers : getApiHeader()});
    },
    postOrderList(param) {
        return axios.post(API_URL + '/admin/orderList', param, {headers : getApiHeader()});
    },
    postOrderExcelList(param) {
        return axios.post(API_URL + '/admin/orderExcelList', param, {headers : getApiHeader()});
    },
    putOrder(param) {
        return axios.put(API_URL + '/admin/order', param, {headers : getApiHeader()});
    },
    postOrderReceipt(param) {
        return axios.put(API_URL + '/admin/orderReceipt', param, {headers : getApiHeader()});
    },
    postGoodsList(param) {
        return axios.post(API_URL + '/admin/goodsList', param, {headers : getApiHeader()});
    },
    postStatisticsList(param) {
        return axios.post(API_URL + '/admin/statisticsList', param, {headers : getApiHeader()});
    }
}
