var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal auth-form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",attrs:{"name":"login[username]","type":"email","placeholder":"Username","id":"exampleInputEmail12"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('span',{staticClass:"block text-danger text-xs absolute bottom-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{ref:"password",attrs:{"name":"password","rules":{
          required: { allowFalse: false },
          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"name":"login[password]","type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"block text-danger text-xs absolute bottom-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"confirm password","vid":"pass","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",attrs:{"name":"login[password]","type":"password","placeholder":"Confirm Password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('span',{staticClass:"block text-danger text-xs absolute bottom-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-terms"},[_c('div',{staticClass:"custom-control custom-checkbox mr-sm-2"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlAutosizing1"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlAutosizing1"}},[_c('span',[_vm._v("I agree all statements in "),_c('a',{attrs:{"href":""}},[_vm._v("Terms & Conditions")])])])])]),_c('div',{staticClass:"form-button"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.register}},[_vm._v(" Register ")])]),_c('div',{staticClass:"form-footer"},[_c('span',[_vm._v("Or Sign up with social platforms")]),_c('ul',{staticClass:"social"},[_c('li',[_c('feather',{staticClass:"icon-facebook",attrs:{"type":"facebook","fill":"#F98085","stroke":"#F98085","size":"16px"}})],1),_c('li',[_c('feather',{staticClass:"icon-twitter",attrs:{"type":"twitter","stroke":"#F98085","size":"16px"}})],1),_c('li',[_c('feather',{staticClass:"icon-instagram",attrs:{"type":"instagram","stroke":"#F98085","size":"16px"}})],1),_c('li',[_c('feather',{staticClass:"icon-instagram",attrs:{"type":"github","stroke":"#F98085","size":"16px"}})],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }