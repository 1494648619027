<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="정산관리" title="정산관리" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Invoice List</h5>-->
<!--            </div>-->
            <div class="card-body">
              <div class="pb-2">
                조회 기간 :
                <date-picker v-model="search.start_date" format="YYYY-MM-DD" type="date" valueType="format" />
                ~
                <date-picker v-model="search.end_date" format="YYYY-MM-DD" type="date" valueType="format" />
                <b-button class="btn ml-2" @click="searchList">조회</b-button>
              </div>

              <div class="table-responsive datatable-vue product-list">
                <div>
                  <b-modal id="modal-2" title="Confirmation" @ok="deleteRow(selectedSku)">
                    <p class="my-4">Are you sure!</p>
                  </b-modal>
                </div>
                <b-table
                  :select-mode="selectMode"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="itemList"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                  ref="selectableTable"
                  :selectable="true"
                  @row-selected="rowSelected"
                >
                  <template #empty="scope">
                    조회된 데이터가 없습니다.
                  </template>
<!--                  <template-->
<!--                    v-for="(field, index) in tablefields"-->
<!--                    :slot="field.key"-->
<!--                    slot-scope="data"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      :key="index"-->
<!--                      class="border-0 no-shadow p-1"-->
<!--                      type="text"-->
<!--                      v-model="data.item[key]"-->
<!--                    ></b-form-input>-->
<!--                  </template>-->
<!--                  <template v-slot:cell()="{ value, item, field: { key } }">-->
<!--                    <template v-if="edit != item.id">{{ value }}</template>-->
<!--                    <b-form-input type="text" v-else v-model="item[key]" />-->
<!--                  </template>-->
                  <templete #cell(calculate)="field">
                    {{field.item.rental_fee + field.item.rental_fee2}}
                  </templete>
                  <templete #cell(sales_type)="field">
                    {{field.item.rental_fee + field.item.rental_fee2}}
                  </templete>
                </b-table>
                <b-col md="12" class="my-1 p-0 pagination-justify">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                  >
                  </b-pagination>
                </b-col>
              </div>
              <div class="row mt-3">
                <div class="col"><b-button class="d-inline-flex" @click="excelDown">엑셀다운</b-button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import nodeApi from "@/api/nodeApi";
import Vue from "vue";
import Xlsx from "xlsx";

export default {
  components: {DatePicker},
  data() {
    return {
      add: false,
      save: false,
      edit: null,
      item: "",
      selectedSku : "",
      index: null,
      addNew: false,
      search: {
        start_date: '',
        end_date: ''
      },
      tablefields: [
        {
          key: "agent_id",
          label: "아이디",
          sortable: true,
          editable: false,
          isDeleted: false
        },
        {
          key: "name",
          label: "이름",
          sortable: false,
          editable: true,
          isDeleted: false
        },
        { key: "sales_type", label: "판매구분", sortable: true },
        { key: "code_name", label: "사용자구분", sortable: true, editable: true },
        { key: "goods_price", label: "판매총액", sortable: true, editable: true },
        { key: "rental_fee", label: "직접판매수수료", sortable: true, editable: true },
        { key: "rental_fee2", label: "간접판매수수료", sortable: true, editable: true },
        { key: "calculate", label: "정산금액", sortable: true, editable: true },
        // { key: "total", label: "", sortable: true, editable: true },
        // { key: "actions" }
      ],
      modes: ["multi", "single", "range"],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selectMode: "single",
      itemList: []
    };
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length;
    let toDay = new Date();
    let preDay = new Date();
    preDay.setDate(toDay.getDate() - 30);

    let preStrDate = preDay.getFullYear() + '-' + ((preDay.getMonth() + 1) > 9 ? (preDay.getMonth() + 1) : '0' + (preDay.getMonth() + 1)) + '-' + (preDay.getDate() > 9 ? preDay.getDate() : '0' + preDay.getDate());
    let preEndDate = toDay.getFullYear() + '-' + ((toDay.getMonth() + 1) > 9 ? (toDay.getMonth() + 1) : '0' + (toDay.getMonth() + 1)) + '-' + (toDay.getDate() > 9 ? toDay.getDate() : '0' + toDay.getDate());

    this.search.start_date = preStrDate;
    this.search.end_date = preEndDate;
    this.searchList();
  },
  created() {
    // this.$store.dispatch("statistics/getStatisticsList");
  },
  computed: {
    ...mapGetters({
      // items: "statistics/getStatisticsList"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    selectedRows() {
      return this.items.filter(item => item.delete);
    },
    // edit row
    editableFields() {
      return this.tablefields.filter(field => {
        return field.editable === true;
      });
    }
  },
  methods: {
    searchList() {
      let param = {
        start_date: this.search.start_date,
        end_date: this.search.end_date
      }

      nodeApi.postStatisticsList(param).then( res => {
        if (res.data.success) {
          // context.commit('getStatisticsList', res.data.statistics_list)
          this.itemList = res.data.statistics_list;
          this.totalRows = this.itemList.length;
          // this.orderExcelList = res.data.order_excel_list;
        }
      }).catch( err => {
        // Vue.$log.error(['get statistics list error', err])
      });

      // nodeApi.postOrderList(param).then( res => {
      //   console.log(['postOrderList', res.data]);
      //   if (res.data.success) {
      //     this.orderList = res.data.order_list;
      //     this.orderExcelList = res.data.order_excel_list;
      //   }
      // });
    },
    excelDown() {
      this.$log.debug(['excelDown']);
      const Heading = [
        ["판매구분", "아이디", "이름", "사용자구분", "판매총액", "직접판매수수료"
          , "간접판매수수료", "정산금액"]
      ]
      const workBook = Xlsx.utils.book_new()
      // const workHeader = Xlsx.utils.sheet_add_aoa(workBook, Heading);
      // Xlsx.utils.book_append_sheet(workBook, workHeader, 'header')
      // const workSheet = Xlsx.utils.sheet_add_json(workBook, this.rentalExcelList, {origin: 'A2', skipHeader: true});

      // this.rentalExcelList.unshift(Heading);
      const workSheet = Xlsx.utils.json_to_sheet(this.itemList, {origin: 'A2', skipHeader: true});


      Xlsx.utils.sheet_add_aoa(workSheet, Heading);
      // const workSheet = Xlsx.utils.shh(this.rentalExcelList, heading);
      // Xlsx.utils.sheet_add_json(workBook, this.rentalExcelList, {origin: 'A2', skipHeader: true})

      Xlsx.utils.book_append_sheet(workBook, workSheet);
      Xlsx.writeFile(workBook, '정산목록.xlsx')
    },
    onEdit(id) {
      if (id !== "undefined") {
        this.edit = this.edit !== id ? id : null;
      }
    },
    getIndex(id) {
      this.selectedSku = id
    },
    onSave(id) {
      this.save = false;
      this.add = false;
      this.edit = 1000;
      //  this.edit = ''
    },
    doCancel(id) {
      this.edit = null;
      this.item = null;
    },
    deleteRow(id) {
      this.items.splice(this.items.findIndex(e => e.id === id),1);
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    addRecord() {
      this.add = true;
      console.log("items", this.items[0]);
      this.items.unshift({
        id: "",
        invoice: "",
        date: "",
        shipping: "",
        amount: "",
        tax: "",
        total: "",
        actions: ""
      });
      this.edit = "";
      this.save = true;
    },
    searchData() {
      this.add = false;
      this.items.splice(0, 1);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
    //b-table customize
  }
};
</script>
