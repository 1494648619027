import Vue from "vue";
import EventEmitter from 'events';
const userlogin = 'islogged';
const loginExpiryKey = 'tokenExpiry';
const Userinfo = 'userinfo';
const localStorageKey = 'loggedIn';
const tokenKey = 'adminToken';
import config from '../../config.json';
const tokenSecret = config.TOKEN_SECRET;
class Auth extends EventEmitter {
    authToken = null
    userProfile = null
    tokenExpiry = null
    accessCookieTime = '1h';

        // Login With Firebase
    localLogin(authResult) {
        Vue.$log.debug(['localLogin authResult',authResult]);
        this.tokenExpiry = new Date();
        const user = authResult.user;
        const userToken = authResult.token;

        Vue.$cookies.set(tokenKey, userToken, this.accessCookieTime);
        localStorage.setItem(loginExpiryKey, this.tokenExpiry);
        localStorage.setItem(userlogin, 'true');
        localStorage.setItem(localStorageKey, 'true');
        localStorage.setItem(Userinfo, JSON.stringify(user));
        localStorage.setItem(tokenKey, authResult.token);
    }
    Logout() {
        Vue.$cookies.remove(tokenKey);
        localStorage.removeItem(loginExpiryKey);
        localStorage.removeItem(userlogin);
        localStorage.removeItem(Userinfo);
        localStorage.removeItem(localStorageKey);
        localStorage.removeItem(tokenKey);

    }

    isAuthenticated() {
        const accessToken = Vue.$cookies.get(tokenKey);
        const isAuth = !!accessToken;
        return !!accessToken;
    }
    isAuthenticatedUser() {
        return (
            new Date(Date.now()) < new Date(localStorage.getItem(loginExpiryKey)) &&
            localStorage.getItem(localStorageKey) === 'true'
        );
    }

}

export default new Auth();
