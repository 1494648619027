<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="렌탈관리" :title="'렌탈상세'" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
<!--        <div class="card-header">-->
<!--          <h5>Add User</h5>-->
<!--        </div>-->
        <div class="card-body">
          <ul class="nav nav-tabs tab-coupon" id="top-tab" role="tablist">
            <div class="tab-content" id="top-tabContent">
              <form class="needs-validation user-add" novalidate="">
<!--                <h4>Account Details</h4>-->
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">주문번호</label>{{item.rental_code}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">상품명</label>{{item.goods_name}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">옵션명</label>{{item.option_name}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">수량</label>{{item.rental_qty}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">계약자</label>{{item.receiver}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">전화번호</label>{{item.receiver_phone}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">휴대번호</label>{{item.receiver_mobile}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">우편번호</label>{{item.receiver_zipcode}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">주소</label>{{item.receiver_address}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">상세주소</label>{{item.receiver_address_etc}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">생년월일</label>{{item.receiver_birth}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">카드번호</label>{{item.card_num}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">카드유효기간</label>{{item.card_validity}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">출금기관</label>{{item.financial_name}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">계약상태</label>
                  <div v-if="isAgent">{{item.contract_status_name}}</div>
                  <b-select v-if="!isAgent" id="user_state" class="form-control col-xl-8 col-md-7" v-model="item.contract_status" :options="options" >
                    <!--                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">회원</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">탈퇴</b-form-select-option>-->
                  </b-select>

                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">배송현황</label>
                  <div v-if="isAgent">{{item.delivery_status_name}}</div>
                  <b-select v-if="!isAgent" id="delivery_state" class="form-control col-xl-8 col-md-7" v-model="item.delivery_status" :options="deliveryOptions" >
                    <!--                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">회원</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">탈퇴</b-form-select-option>-->
                  </b-select>

                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">배송정보</label>
                  <div v-if="isAgent">{{item.delivery_status_name}}</div>
                  <div v-if="!isAgent" class="d-flex">
                  <b-select id="delivery_company" class="form-control col-xl-8 col-md-7 d-flex w-auto" v-model="item.delivery_company" :options="deliveryCompanies" >
                    <!--                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">회원</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">탈퇴</b-form-select-option>-->
                  </b-select>
                    <input type="text" class="form-control d-flex w-auto" placeholder="송장번호 입력" v-model="item.delivery_number" />
                  </div>

                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">주문일자</label>{{item.reg_date}}
                </div>
              </form>
            </div>
          </ul>
          <div class="pull-right">
            <button v-if="!isAgent" type="button" class="btn btn-primary" @click="saveItem()">저장</button>
            <router-link to="/sales/rental"><button type="button" class="btn btn-dark ml-2">목록</button></router-link>
          </div>
        </div>

      </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import nodeApi from "@/api/nodeApi";

export default {
  data() {
    return {
      content: "<h1>Some initial content</h1>",
      options: [
        {value: 'CONTRACT_STATUS01', text: '계약대기'},
        {value: 'CONTRACT_STATUS02', text: '계약진행'},
        {value: 'CONTRACT_STATUS10', text: '계약종료'},

      ],
      deliveryOptions: [
        {value: 'DELIVERY_STATUS01', text: '계약확인'},
        {value: 'DELIVERY_STATUS02', text: '배송준비중'},
        {value: 'DELIVERY_STATUS03', text: '배송중'},
        {value: 'DELIVERY_STATUS04', text: '배송완료'},
        {value: 'DELIVERY_STATUS08', text: '교환'},
        {value: 'DELIVERY_STATUS09', text: '반품'},
      ],
      deliveryCompanies: [
        {value: null, text: '택배사선택'},
        {value: 1, text: 'CJ대한통운'},
        {value: 2, text: '롯데택배'},
        {value: 3, text: '우체국택배'},
        {value: 4, text: '한진택배'},
        {value: 5, text: '로젠택배'},
        {value: 7, text: '경동화물'},
        {value: 8, text: '대신화물'},
      ],
      isEdit: false,
      isAgent: false
    };
  },
  props: ['item'],
  created() {
    const user = JSON.parse(localStorage.getItem('userinfo'));

    if (user.user_type === 'USER_TYPE10' || user.user_type === 'USER_TYPE05') {
      this.isAgent = true;
    }
  },
  methods: {
    onComplete() {
    },
    saveItem() {
      nodeApi.putRental(this.item).then( res => {
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, "success", 2000);

          this.$router.push('/sales/rental');
        } else {
          nodeApi.showToast(res.data.msg);
        }
      }).catch( err => {
        nodeApi.showToast(err.toString());
      })
    }
  }
};
</script>
<style scoped>

</style>
