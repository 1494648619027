import Vue from 'vue'
import VueRouter from 'vue-router'
import Body from '../components/Body.vue';
// import dashboard from '../pages/dashboard.vue';
// import category from '../pages/physical/category';
// import AddProduct from '../pages/physical/AddProduct';
// import SubCategory from '../pages/physical/SubCategory.vue';
// import ProductList from '../pages/physical/ProductList.vue';
// import ProductDetail from '../pages/physical/ProductDetail.vue';
// import CategoryDigital from '../pages/digital/Category.vue';
// import SubCategoryDigital from '../pages/digital/SubCategory.vue';
// import ProductListDigital from '../pages/digital/ProductList.vue';
// import AddProductDigital from '../pages/digital/AddProduct.vue';
import Order from '../pages/sales/order.vue';
import OrderView from '../pages/sales/order-view.vue';
import Rental from '../pages/sales/rental.vue';
import RentalView from '../pages/sales/rental-view.vue';
// import transactions from '../pages/sales/Transaction.vue';
// import CouponList from '../pages/coupon/CouponList.vue';
// import AddCoupon from '../pages/coupon/AddCoupon.vue';
// import PageList from '../pages/page/PageList.vue';
// import CreatePage from '../pages/page/create-page.vue';
// import Media from '../pages/media.vue';
// import MenuList from '../pages/menus/menu-list.vue';
// import CreateMenu from '../pages/menus/create-menu.vue';
import UserList from '../pages/users/user-list.vue';
import CreateUser from '../pages/users/create-user.vue';
// import VendorList from '../pages/vendors/vendor-list.vue';
// import CreateVendor from '../pages/vendors/create-vendor.vue';
// import LocalTranslation from '../pages/localization/translations.vue';
// import CurrencyRates from '../pages/localization/currency-rates.vue'
// import Taxes from '../pages/localization/taxes.vue';
// import Reports from '../pages/reports.vue';
// import Profile from '../pages/profiles/profile.vue';
// import Invoice from '../pages/invoice.vue';
import Auth from '../pages/authentication/index.vue';
// import firebase from 'firebase/app';
import Userauth from '../auth/index.js'
import GoodsList from '../pages/goods/GoodsList';
import AddGoods from '../pages/goods/AddGoods';
import Statistics from '../pages/statistics';

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: Body,
        redirect: { name: 'order' },
        children: [
            {
                path: '/statistics',
                name: 'statistics',
                component: Statistics,
                meta: {
                    title: 'Invoice | Multikart - Premium Admin Template',

                }
            }
        ]
    },

    {
        path: '/sales',
        component: Body,
        children: [{
            path: 'order',
            name: 'order',
            component: Order,
            meta: {
                title: 'Order | Multikart - Premium Admin Template',
            }
            },
            {
                path: 'order-view',
                name: 'orderView',
                component: OrderView,
                props: true,
                meta: {
                    title: 'Order | Multikart - Premium Admin Template',
                }
            },
            {
                path: 'rental',
                name: 'rental',
                component: Rental,
                meta: {
                    title: 'Order | Multikart - Premium Admin Template',
                }
            },
            {
                path: 'rental-view',
                name: 'rentalView',
                component: RentalView,
                props: true,
                meta: {
                    title: 'Order | Multikart - Premium Admin Template',
                }
            },
        ]
    },
    {
        path: '/goods',
        component: Body,
        children: [
            {
                path: 'goods-list',
                name: 'goods-list',
                component: GoodsList,
                meta: {
                    title: 'Product List | Multikart - Premium Admin Template',
                }
            },
            {
                path: 'add-goods',
                name: 'add-goods',
                component: AddGoods,
                meta: {
                    title: 'Add product | Multikart - Premium Admin Template',
                }
            },
        ]
    },
    {
        path: '/users',
        name: 'users',
        component: Body,
        children: [{
                path: 'user-list',
                name: 'user-list',
                component: UserList,
                meta: {
                    title: 'User | Multikart - Premium Admin Template',

                }
            },
            {
                path: 'create-user',
                name: 'create-user',
                component: CreateUser,
                props: true,
                meta: {
                    title: 'User | Multikart - Premium Admin Template',

                }
            }
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: Auth,
        meta: {
            title: 'Login | Multikart - Premium Admin Template',

        }
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // next();

    // const CurrentUser = Userauth.userProfile;

    // console.log(['befor', Userauth.isAuthenticated(), to.path]);

    const path = ['/auth/login', '/register'];

    // if (Userauth.isAuthenticated()) {
    //     if (path.includes(to.path) || to.path === "/callback") {
    //         next('/');
    //     } else {
    //         return next();
    //     }
    // } else {
    //     return next('/auth/login');
    // }

    Vue.$log.debug(['router pre isAuthenticated', Userauth.isAuthenticated()])

    if (path.includes(to.path) || to.path === "/callback" || Userauth.isAuthenticated()) {
        return next();
    }
    next('/auth/login')


//     firebase.auth().onAuthStateChanged(() => {
//         if (to.meta.title)
//             document.title = to.meta.title;
//         const CurrentUser = firebase.auth().currentUser;
//         const path = ['/auth/login', '/register'];
//         if (path.includes(to.path) || to.path === "/callback" || CurrentUser || Userauth.isAuthenticatedUser()) {
//             return next();
//         }
//         next('/auth/login')
//     });
});

export default router
