<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="주문관리" :title="'주문상세'" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
<!--        <div class="card-header">-->
<!--          <h5>Add User</h5>-->
<!--        </div>-->
        <div class="card-body">
          <ul class="nav nav-tabs tab-coupon" id="top-tab" role="tablist">
            <div class="tab-content" id="top-tabContent">
              <form class="needs-validation user-add" novalidate="">
<!--                <h4>Account Details</h4>-->
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">주문번호</label>{{item.order_code}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">상품명</label>{{item.goods_name}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">옵션명</label>{{item.option_name}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">수량</label>{{item.order_qty}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">결제방법</label>{{item.pay_type_name}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">결제금액</label>{{item.pay_amount | makeComma}}원
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">계약자</label>{{item.receiver}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">전화번호</label>{{item.receiver_phone}}
                </div>
                <div v-if="item.pay_type === 'PAY_TYPE01'">
                  <div class="form-group row">
                    <label class="col-xl-3 col-md-4">카드사</label>{{item.payment_name}}
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-md-4">카드번호</label>{{item.payment_num}} (보안상 중간번호 0000 처리됨)
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-md-4">승인번호</label>{{item.approval_num}} <b-button class="btn ml-3" @click="viewReceipt" v-if="false">영수증보기</b-button><span v-if="!item.approval_num">(승인번호 없는 경우 서버 검증필요)</span>
                  </div>
                </div>
<!--                <div class="form-group row">-->
<!--                  <label class="col-xl-3 col-md-4">휴대번호</label>{{item.receiver_mobile}}-->
<!--                </div>-->
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">우편번호</label>{{item.receiver_zipcode}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">주소</label>{{item.receiver_address}}
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">상세주소</label>{{item.receiver_address_etc}}
                </div>
<!--                <div class="form-group row">-->
<!--                  <label class="col-xl-3 col-md-4">생년월일</label>{{item.receiver_birth}}-->
<!--                </div>-->
<!--                <div class="form-group row">-->
<!--                  <label class="col-xl-3 col-md-4">카드번호</label>{{item.card_num}}-->
<!--                </div>-->
<!--                <div class="form-group row">-->
<!--                  <label class="col-xl-3 col-md-4">카드유효기간</label>{{item.card_validity}}-->
<!--                </div>-->
<!--                <div class="form-group row">-->
<!--                  <label class="col-xl-3 col-md-4">출금기관</label>{{item.financial_name}}-->
<!--                </div>-->
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">배송현황</label>
                  <div v-if="isAgent || !isOrderEdit">{{item.order_status_name}}</div>
                  <b-select v-if="!isAgent && isOrderEdit" id="order_state" class="form-control col-xl-8 col-md-7" v-model="item.order_status" :options="options" >
                    <!--                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">회원</b-form-select-option>-->
                    <!--                    <b-form-select-option value="">탈퇴</b-form-select-option>-->
                  </b-select>

                </div>
                <div class="form-group row" v-if="item.order_status === 'ORDER_STATUS04' || item.order_status === 'ORDER_STATUS09'">
                  <label class="col-xl-3 col-md-4">취소(반품)사유</label>
                  <div v-if="isAgent || !isOrderEdit">{{item.cancel_reason}}</div>
                  <input v-if="!isAgent && isOrderEdit" type="text" class="form-control col-xl-8 col-md-7" v-model="item.cancel_reason">
                </div>
<!--                <div class="form-group row">-->
<!--                  <label class="col-xl-3 col-md-4">배송현황</label>-->
<!--                  <div v-if="isAgent">{{item.delivery_status_name}}</div>-->
<!--                  <b-select v-if="!isAgent" id="delivery_state" class="form-control col-xl-8 col-md-7" v-model="item.delivery_status" :options="deliveryOptions" >-->
<!--                    &lt;!&ndash;                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <b-form-select-option value="">회원</b-form-select-option>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <b-form-select-option value="">탈퇴</b-form-select-option>&ndash;&gt;-->
<!--                  </b-select>-->

<!--                </div>-->
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">배송정보</label>
                  <div v-if="isAgent">{{item.company_name}} : {{item.delivery_number}}</div>
                  <div v-if="!isAgent" class="d-flex">
                    <b-select id="delivery_company" class="form-control col-xl-8 col-md-7 d-flex w-auto" v-model="item.delivery_company" :options="deliveryCompanies" >
                      <!--                    <b-form-select-option value="waiting" v-if="users.user_state === 'waiting' ? 'selected' : ''">대기</b-form-select-option>-->
                      <!--                    <b-form-select-option value="">회원</b-form-select-option>-->
                      <!--                    <b-form-select-option value="">탈퇴</b-form-select-option>-->
                    </b-select>
                    <input type="text" class="form-control d-flex w-auto" placeholder="송장번호 입력" v-model="item.delivery_number" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-md-4">주문일자</label>{{item.reg_date}}
                </div>
              </form>
            </div>
          </ul>
          <div class="pull-right">
            <button v-if="!isAgent" type="button" class="btn btn-primary" @click="saveItem()">저장</button>
            <router-link to="/sales/order"><button type="button" class="btn btn-dark ml-2">목록</button></router-link>
          </div>
        </div>

      </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import nodeApi from "@/api/nodeApi";

export default {
  data() {
    return {
      content: "<h1>Some initial content</h1>",
      options: [
        {value: 'ORDER_STATUS01', text: '주문완료'},
        {value: 'ORDER_STATUS02', text: '배송중'},
        {value: 'ORDER_STATUS03', text: '배송완료'},
        {value: 'ORDER_STATUS04', text: '주문취소'},
        {value: 'ORDER_STATUS08', text: '교환'},
        {value: 'ORDER_STATUS09', text: '반품'},

      ],
      deliveryOptions: [
        {value: 'DELIVERY_STATUS01', text: '계약확인'},
        {value: 'DELIVERY_STATUS02', text: '배송준비중'},
        {value: 'DELIVERY_STATUS03', text: '배송중'},
        {value: 'DELIVERY_STATUS04', text: '배송완료'},
        {value: 'DELIVERY_STATUS08', text: '교환'},
        {value: 'DELIVERY_STATUS09', text: '반품'},
      ],
      deliveryCompanies: [
        {value: null, text: '택배사선택'},
        {value: 1, text: 'CJ대한통운'},
        {value: 2, text: '롯데택배'},
        {value: 3, text: '우체국택배'},
        {value: 4, text: '한진택배'},
        {value: 5, text: '로젠택배'},
        {value: 7, text: '경동화물'},
        {value: 8, text: '대신화물'},
      ],
      isEdit: false,
      isAgent: false,
      isOrderEdit: true
    };
  },
  props: ['item'],
  created() {
    const user = JSON.parse(localStorage.getItem('userinfo'));

    if (user.user_type === 'USER_TYPE10' || user.user_type === 'USER_TYPE05') {
      this.isAgent = true;
    }

    if (this.item.pay_type === 'PAY_TYPE01' && (this.item.order_status === 'ORDER_STATUS04' || this.item.order_status === 'ORDER_STATUS09')) {
      this.isOrderEdit = false
    }

  },
  methods: {
    onComplete() {
    },
    saveItem() {
      const settingsObject = {singular: false, position: 'center-center', theme: 'light', iconEnabled: false, hideProgressbar: true, withBackdrop: true}
      this.$vToastify.setSettings(settingsObject);

      if (this.item.pay_type === 'PAY_TYPE01' && (this.item.order_status === 'ORDER_STATUS04' || this.item.order_status === 'ORDER_STATUS09')) {
        this.$vToastify.prompt({
          body: '주문취소/반품 처리하는 경우 카드 결제가 취소됩니다.<br>진행하시겠습니까?',
          answers: {'진행': true, '닫기': false}
        }).then( value => {
          if (value) {
            this.putOrder();
          }
        });
      } else {
        this.putOrder();
      }




    },
    putOrder() {
      nodeApi.putOrder(this.item).then( res => {
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, "success", 2000);

          this.$router.push('/sales/order');
        } else {
          nodeApi.showToast(res.data.msg);
        }
      }).catch( err => {
        nodeApi.showToast(err.toString());
      })
    },
    viewReceipt() {

      nodeApi.postOrderReceipt(this.item).then( res => {
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, "success", 2000);

          this.$router.push('/sales/order');
        } else {
          nodeApi.showToast(res.data.msg);
        }
      }).catch( err => {
        nodeApi.showToast(err.toString());
      })
    }
  }
};
</script>
<style scoped>

</style>
