<template>
  <div
    :class="toggleChatSidebar ? 'right-sidebar show' : 'right-sidebar'"
    id="right_side_bar"
  >
    <div>
      <div class="container p-0">
        <div class="modal-header p-l-20 p-r-20">
          <div class="col-sm-8 p-0">
            <h6 class="modal-title font-weight-bold">FRIEND LIST</h6>
          </div>
          <div class="col-sm-4 text-right p-0">
            <i class="mr-2"><feather type="settings"></feather></i>
          </div>
        </div>
      </div>
      <div class="friend-list-search mt-0">
        <!--<input type="text" placeholder="search friend" /><i
          class="mr-2 fa fa-search"
        ></i>-->

        <input type="text" placeholder="search friend" />
        <i class="fas fa-search"></i>
      </div>
      <div class="p-l-30 p-r-30">
        <div class="chat-box">
          <div class="people-list friend-list custom-scrollbar">
            <ul class="list">
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/user.png"
                  alt=""
                />
                <div class="status-circle online"></div>
                <div class="about">
                  <div class="name">Vincent Porter</div>
                  <div class="status">Online</div>
                </div>
              </li>
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/user1.jpg"
                  alt=""
                />
                <div class="status-circle away"></div>
                <div class="about">
                  <div class="name">Ain Chavez</div>
                  <div class="status">28 minutes ago</div>
                </div>
              </li>
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/user2.jpg"
                  alt=""
                />
                <div class="status-circle online"></div>
                <div class="about">
                  <div class="name">Kori Thomas</div>
                  <div class="status">Online</div>
                </div>
              </li>
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/user3.jpg"
                  alt=""
                />
                <div class="status-circle online"></div>
                <div class="about">
                  <div class="name">Erica Hughes</div>
                  <div class="status">Online</div>
                </div>
              </li>
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/man.png"
                  alt=""
                />
                <div class="status-circle offline"></div>
                <div class="about">
                  <div class="name">Ginger Johnston</div>
                  <div class="status">2 minutes ago</div>
                </div>
              </li>
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/user5.jpg"
                  alt=""
                />
                <div class="status-circle away"></div>
                <div class="about">
                  <div class="name">Prasanth Anand</div>
                  <div class="status">2 hour ago</div>
                </div>
              </li>
              <li class="clearfix">
                <img
                  class="rounded-circle user-image"
                  src="../assets/images/dashboard/designer.jpg"
                  alt=""
                />
                <div class="status-circle online"></div>
                <div class="about">
                  <div class="name">Hileri Jecno</div>
                  <div class="status">Online</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chatSidebar: false,
      imageUrl: "@/assets/images/dashboard/"
    };
  },
  computed: {
    toggleChatSidebar() {
      return this.$store.state.menu.toggleChatBar;
    }
  },
  methods: {
    openChatSidebar() {
      if (this.chatSidebar == false) {
        this.chatSidebar = true;
      } else {
        this.chatSidebar = false;
      }
    }
  }
};
</script>