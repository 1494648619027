<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="상품" title="상품목록" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Product Lists</h5>-->
<!--            </div>-->
            <div class="card-body">
              <div class="table-responsive datatable-vue">
                <b-modal id="modal-2" title="Confirmation" @ok="deleteRow(selectedSku)" ok-title="확인" cancel-title="취소">
                  <p class="my-4">Are you sure!</p>
                </b-modal>
<!--                <router-link to="/goods/add-goods">-->
<!--                  <b-button variant="info" class="btn-popup pull-right"-->
<!--                  >상품 등록</b-button-->
<!--                  ></router-link-->
<!--                >-->
                <b-table
                  striped
                  hover
                  class="text-center"
                  bordered
                  head-variant="light"
                  show-empty
                  stacked="md"
                  :items="goodsList"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                  :select-mode="selectMode"
                  :selectable="true"
                  @row-selected="rowSelected"
                >
                  <template #empty="scope">
                    조회된 데이터가 없습니다.
                  </template>
<!--                  <template v-slot:cell()="{ value, item, field: { key } }">-->
<!--                    <template v-if="edit != item.id">{{ value }}</template>-->
<!--                    <b-form-input type="text" v-else v-model="item[key]" />-->
<!--                  </template>-->
<!--                  <template #cell(status) v-for="(item, index) in goodsList">-->
<!--                    <feather-->
<!--                      v-if="item.status == true"-->
<!--                      :key="index"-->
<!--                      type="circle"-->
<!--                      stroke="#81BA01"-->
<!--                      stroke-width="1"-->
<!--                      size="10px"-->
<!--                      fill="#81BA01"-->
<!--                      stroke-linejoin="round"-->
<!--                    ></feather>-->

<!--                    <feather-->
<!--                      v-else-if="item.status == false"-->
<!--                      :key="index"-->
<!--                      type="circle"-->
<!--                      stroke="#FBBC58"-->
<!--                      stroke-width="1"-->
<!--                      size="10px"-->
<!--                      fill="#FBBC58"-->
<!--                      stroke-linejoin="round"-->
<!--                    ></feather>-->
<!--                  </template>-->
                 <template #cell(image)="field">
                    <img
                      height="50px"
                      :src=" imageUrl + '/goods/'+field.item.goods_code+'/main_1.jpg'"
                      width="50px"
                    />
                  </template>

<!--                  <template v-slot:cell(actions)="{ item: { id } }">-->
<!--                    <span-->
<!--                      style="font-size: 20px; color: blue;"-->
<!--                      v-if="edit != id && id !== null"-->
<!--                      @click="onEdit(id)"-->
<!--                    >-->
<!--                      <feather-->
<!--                        type="edit-2"-->
<!--                        stroke="#3758FD"-->
<!--                        stroke-width="1"-->
<!--                        size="18px"-->
<!--                        fill="#3758FD"-->
<!--                        stroke-linejoin="round"-->
<!--                      ></feather>-->
<!--                    </span>-->
<!--                    <span-->
<!--                      v-if="edit != id"-->
<!--                      v-b-modal.modal-2-->
<!--                      @click="getIndex(id)"-->
<!--                      style="font-size: 20px; color: #FA399F;"-->
<!--                    >-->
<!--                      <feather-->
<!--                        type="trash"-->
<!--                        stroke="#F72E9F"-->
<!--                        size="18px"-->
<!--                        fill="#F72E9F"-->
<!--                      ></feather>-->
<!--                    </span>-->
<!--                    <feather-->
<!--                      v-if="edit === id && id !== ''"-->
<!--                      size="18px"-->
<!--                      stroke="#39CCC6"-->
<!--                      stroke-width="5"-->
<!--                      type="check"-->
<!--                      @click="onSave(id)"-->
<!--                    ></feather>-->

<!--                    <feather-->
<!--                      stroke="#F7299D"-->
<!--                      size="18px"-->
<!--                      stroke-width="5"-->
<!--                      v-if="edit === id && id !== ''"-->
<!--                      type="x"-->
<!--                      @click="doCancel(id)"-->
<!--                    ></feather>-->
<!--                    <feather-->
<!--                      stroke="#F7299D"-->
<!--                      size="18px"-->
<!--                      stroke-width="5"-->
<!--                      v-if="save && id == ''"-->
<!--                      type="save"-->
<!--                      @click="onSave(id)"-->
<!--                    ></feather>-->
<!--                  </template>-->
<!--                  <template v-slot:head(actions)>-->
<!--                    <feather-->
<!--                      v-if="!add"-->
<!--                      type="plus"-->
<!--                      stroke="#4050F6"-->
<!--                      stroke-width="6"-->
<!--                      size="22px"-->
<!--                      fill="#4050F6"-->
<!--                      @click="addRecord()"-->
<!--                    ></feather>-->
<!--                    <feather-->
<!--                      v-if="add"-->
<!--                      type="search"-->
<!--                      stroke-width="6"-->
<!--                      size="22px"-->
<!--                      @click="searchData()"-->
<!--                    ></feather>-->
<!--                  </template>-->
                </b-table>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                  ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import nodeApi from "@/api/nodeApi";

export default {
  data() {
    return {
      tablefields: [
        { key: "goods_code", label: "상품코드", sortable: false },
        { key: "image", label: "이미지", sortable: false },
        { key: "goods_name", label: "상풍명", sortable: false },
        { key: "goods_price", label: "판매가", sortable: false },
        { key: "pay_fee", label: "카드 직접판매수수료", sortable: false },
        { key: "pay_fee2", label: "카드 간접판매수수료", sortable: false },
        { key: "rental_fee", label: "렌탈 직접판매수수료", sortable: false },
        { key: "rental_fee2", label: "렌탈 간접판매수수료", sortable: false },
        { key: "cash_fee", label: "현금 직접판매수수료", sortable: false },
        { key: "cash_fee2", label: "현금 간접판매수수료", sortable: false },
        { key: "reg_date", label: "등록일", sortable: true },
        // { key: "quantity", label: "Quantity", sortable: false },
        // { key: "actions" }
      ],

      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: "",
      selectedSku : "",
      index: null,
      selectMode: "single",
      imageUrl: ''
    };
  },
  created() {
    this.$store.dispatch("goods/goodsList");
    this.imageUrl = nodeApi.imgUrl();
  },
  computed: {
    ...mapGetters({
      goodsList: "goods/getGoodsList"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    selectedRows() {
      return this.goodsList.filter(item => item.delete);
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.goodsList.length;
  },
  methods: {
  getIndex(id) {
      this.selectedSku = id
    },
   deleteRow(id) {
      this.goodsList.splice(this.goodsList.findIndex(e => e.id === id),1);
    },
    getImgUrl(path) {
      // return require("@/assets/images/digital-product/" + path);
      return '';
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    onSave(id) {
      this.save = false;
      this.add = false;
      this.edit = 1000;
      //  this.edit = ''
    },
    doCancel(id) {
      this.edit = null;
      this.goodsList = null;
    },
    addRecord() {
      this.add = true;
      this.goodsList.unshift({
        id: "",
        images: "main.jpg",
        name: "",
        entryType: "",
        quantity: "",
        actions: ""
      });
      this.edit = "";
      this.save = true;
    },
    searchData() {
      this.add = false;
      this.goodsList.splice(0, 1);
    },
    onEdit(id) {
      if (id !== "undefined") {
        this.edit = this.edit !== id ? id : null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
