<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="렌탈관리" title="렌탈목록" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Manage order</h5>-->
<!--            </div>-->

            <div class="card-body">
              <b-row>
                <b-col xl="3" lg="4" md="6">
                  <b-form-group
                    label-cols="3"
                    label="목록수"
                    class="datatable-select"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="offset-xl-6 offset-lg-2 search-rs" xl="3" lg="5" md="6">
                  <b-form-group
                    label-cols="3"
                    label="검색:"
                    class="datatable-select"
                  >
                    <b-form-input
                      type="text"
                      v-model="filter"
                      placeholder="검색어"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pb-2">
                조회 기간 :
                <date-picker v-model="search.start_date" format="YYYY-MM-DD" type="date" valueType="format" />
                ~
                <date-picker :value="search.end_date" format="YYYY-MM-DD" type="date" valueType="format" />
                <b-button class="btn ml-2" @click="getList">조회</b-button>
              </div>
              <div class="table-responsive datatable-vue text-center">
                <b-table
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  responsive
                  sticky-header
                  stacked="md"
                  sort-by="reg_date"
                  :sort-desc="true"
                  :items="rentalList"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                  <colgroup>
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:9%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                    <col style="width:8%;" />
                  </colgroup>
                  <template #empty="scope">
                    조회된 데이터가 없습니다.
                  </template>
                  <template v-slot:head(rental_code)>
                    <input type="checkbox" @click="checkAll" ref="checkAll">
                  </template>
                  <template #table-colgroup >

                  </template>
                  <template #cell(rental_code)="field">
                    <input type="checkbox" :id="'check_' + field.item.rental_code" :ref="'check_' + field.item.rental_code" :value="field.item.rental_code" @click="checkedCode"  />
                  </template>
                  <template #cell(goods_name)="field">
                    <a href="javascript:;" @click="selectRental(field.item)">{{ field.item.goods_name }}</a>
                  </template>
<!--                  <template #cell(images)="field" class="d-flex">-->
<!--                    <img-->
<!--                      height="50px"-->
<!--                      :src="getImgUrl(field.item.images)"-->
<!--                      width="50px"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <template #cell(paymentStatus)="field">-->
<!--                    <div-->
<!--                      v-if="field.item.paymentStatus == 'Cash On Delivered'"-->
<!--                      class="badge badge-glow badge-secondary"-->
<!--                    >-->
<!--                      {{ field.item.paymentStatus }}-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="field.item.paymentStatus == 'Payment Failed'"-->
<!--                      class="badge badge-glow badge-danger"-->
<!--                    >-->
<!--                      {{ field.item.paymentStatus }}-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="field.item.paymentStatus == 'Paid'"-->
<!--                      class="badge badge-glow badge-success"-->
<!--                    >-->
<!--                      {{ field.item.paymentStatus }}-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="-->
<!--                        field.item.paymentStatus == 'Awaiting Authentication'-->
<!--                      "-->
<!--                      class="badge badge-glow badge-warning"-->
<!--                    >-->
<!--                      {{ field.item.paymentStatus }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template #cell(orderStatus)="field">-->
<!--                    <div-->
<!--                      v-if="field.item.orderStatus == 'Shipped'"-->
<!--                      class="badge badge-glow badge-primary"-->
<!--                    >-->
<!--                      {{ field.item.orderStatus }}-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="field.item.orderStatus == 'Cancelled'"-->
<!--                      class="badge badge-glow badge-danger"-->
<!--                    >-->
<!--                      {{ field.item.orderStatus }}-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="field.item.orderStatus == 'Processing'"-->
<!--                      class="badge badge-glow badge-warning"-->
<!--                    >-->
<!--                      {{ field.item.orderStatus }}-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="field.item.orderStatus == 'Delivered'"-->
<!--                      class="badge badge-glow badge-success"-->
<!--                    >-->
<!--                      {{ field.item.orderStatus }}-->
<!--                    </div>-->
<!--                  </template>-->
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                ></b-pagination>

              </b-col>
              <div class="row mt-3" v-if="!isAgent">

                <div class="col">
                  <div>
                    <span>선택항목 계약상태를</span><b-select class="form-control d-inline-flex w-auto ml-2" :options="options" v-model="selectedOption"></b-select>
                    <b-button class="ml-2" @click="changeStatus(1)">변경</b-button>
                  </div>
                  <div class="mt-2">
                    <span>선택항목 배송현황을</span><b-select class="form-control d-inline-flex w-auto ml-2" :options="deliveryOptions" v-model="selectedDelivery"></b-select>
                    <b-button class="ml-2" @click="changeStatus(2)">변경</b-button>
                  </div>
                </div>
                <div class="col text-right"><b-button class="d-inline-flex" @click="excelDown">엑셀다운</b-button></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import Xlsx from 'xlsx';
import nodeApi from "@/api/nodeApi";

export default {
  comments: {DatePicker},
  data() {
    return {
      value: "",
      search: {
        start_date: '',
        end_date: ''
      },
      rentalList: [],
      rentalExcelList: [],
      tablefields: [
        { key: "rental_code"},
        { key: "goods_name", label: "상품명", sortable: false, thClass: "w15" },
        { key: "option_name", label: "옵션명", sortable: false },
        { key: "rental_qty", label: "수량", sortable: false },
        { key: "receiver", label: "계약자", sortable: true },
        // { key: "receiver_phone", label: "전화번호", sortable: true },
        { key: "receiver_mobile", label: "휴대번호", sortable: false },
        // { key: "receiver_zipcode", label: "우편번호", sortable: false },
        { key: "receiver_address", label: "주소", sortable: false },
        { key: "receiver_address_etc", label: "상세주소", sortable: false },
        { key: "receiver_birth", label: "생년월일", sortable: false },
        { key: "card_num", label: "카드번호", sortable: false },
        { key: "card_validity", label: "카드유효기간", sortable: false },
        { key: "financial_name", label: "출금기관", sortable: false },
        { key: "contract_status_name", label: "계약상태", sortable: true },
        { key: "delivery_status_name", label: "배송현황", sortable: true },
        { key: "reg_date", label: "주문일자", sortable: true }
      ],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      selectedCodes: [],
      selectedOption: null,
      selectedDelivery: null,
      options: [
        {value: null, text: '선택하세요'},
        {value: 'CONTRACT_STATUS01', text: '계약대기'},
        {value: 'CONTRACT_STATUS02', text: '계약진행'},
        {value: 'CONTRACT_STATUS10', text: '계약종료'},

      ],
      deliveryOptions: [
        {value: null, text: '선택하세요'},
        {value: 'DELIVERY_STATUS01', text: '계약확인'},
        {value: 'DELIVERY_STATUS02', text: '배송준비중'},
        {value: 'DELIVERY_STATUS03', text: '배송중'},
        {value: 'DELIVERY_STATUS04', text: '배송완료'},
        {value: 'DELIVERY_STATUS08', text: '교환'},
        {value: 'DELIVERY_STATUS09', text: '반품'},
      ],
      isAgent: false,
    };
  },
  asyncData() {
  },
  created() {
    // this.$store.dispatch("rental/getRentalList");
    // this.$store.dispatch("rental/rentalExcelList");
    const user = JSON.parse(localStorage.getItem('userinfo'));

    if (user.user_type === 'USER_TYPE10' || user.user_type === 'USER_TYPE05') {
      this.isAgent = true;
    }
  },
  computed: {
    ...mapGetters({
      // rentalList: "rental/getRentalList",
      // rentalExcelList: "rental/getRentalExcelList"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    let toDay = new Date();
    let preDay = new Date();
    preDay.setDate(toDay.getDate() - 30);

    let preStrDate = preDay.getFullYear() + '-' + ((preDay.getMonth() + 1) > 9 ? (preDay.getMonth() + 1) : '0' + (preDay.getMonth() + 1)) + '-' + (preDay.getDate() > 9 ? preDay.getDate() : '0' + preDay.getDate());
    let preEndDate = toDay.getFullYear() + '-' + ((toDay.getMonth() + 1) > 9 ? (toDay.getMonth() + 1) : '0' + (toDay.getMonth() + 1)) + '-' + (toDay.getDate() > 9 ? toDay.getDate() : '0' + toDay.getDate());

    this.search.start_date = preStrDate;
    this.search.end_date = preEndDate;

    // this.totalRows = this.rentalList.length;
    this.getList();
  },
  methods: {
    getList() {
      let param = {
        start_date: this.search.start_date,
        end_date: this.search.end_date
      }
      nodeApi.postRentalList(param).then( res => {
        this.$log.debug(['postRentalList', res.data])
        if (res.data.success) {
          this.rentalList = res.data.rental_list;
          this.rentalExcelList = res.data.rental_excel_list;
          this.totalRows = this.rentalList.length;
          // this.orderExcelList = res.data.order_excel_list;
        }
      });
    },
    getImgUrl(path) {
      return require("@/assets/images/dashboard/product/" + path);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(['onFiltered', filteredItems])
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRental(item) {
      this.$log.debug(['item', item])
      this.$router.push({name: 'rentalView', params: { item: item }, props: true});
    },
    checkAll(e) {
      this.$log.debug(['checkall', e.target.checked]);
      if (e.target.checked) {
        this.selectedCodes = [];
        for (let i = 0; i < this.rentalList.length; i++) {
          console.log(['i :' + i, this.rentalList[i].rental_code])
          if (i === this.perPage) break;
          console.log(['checked', this.$refs["check_"+this.rentalList[i].rental_code]]);
          this.$refs["check_"+this.rentalList[i].rental_code].checked = true;
          this.selectedCodes.push(this.rentalList[i].rental_code);
        }
      } else {
        for (let i = 0; i < this.selectedCodes.length; i++) {
          this.$refs["check_"+this.selectedCodes[i]].checked = false;
        }
        this.selectedCodes = [];
      }

      console.log(['selectedCodes', this.selectedCodes]);
    },
    checkedCode(e) {
      console.log(['selectedCodes', e.target.checked, this.selectedCodes, e.target._value]);

      if (e.target.checked) {
        // 체크시 배열에 추가
        this.selectedCodes.push(e.target._value);
      } else {
        for (let i = 0; i < this.selectedCodes.length; i++) {
          if (this.selectedCodes[i] === e.target._value) {
            this.selectedCodes.splice(i, 1);
            i--;
          }
        }

        console.log(['selectedCodes', this.selectedCodes]);
      }

      console.log(['selectedCodes', this.selectedCodes]);
    },
    excelDown() {
      this.$log.debug(['excelDown']);
      const Heading = [
          ["접수일시", "고객구분", "계약자", "전화번호", "휴대번호", "상품그룹"
          , "상품명", "옵션명", "제조사명", "영업그룹", "매입금액", "월렌탈료", "렌탈기간", "우편번호", "주소", "상세주소", "영업지사", "가입경로", "주문번호"
          , "방송일자", "비고사항", "담당자", "연계코드/영업회차","영업지점", "고객타입", "참고사항", "특이사항", "세이프키", "생년월일", "사업자번호"
          , "이메일주소", "계약시작일", "계약종료일", "결제방식", "출금시작일", "월출금일", "카드유효기간", "예금주명", "예금주생년월일", "출금기관"
          , "카드번호", "계좌번호", "설치완료일", "계약상태", "회계일시", "회계번호", "판매구분", "설치시리얼", "설치기사", "설치예정일", "사업자명", "현금영수증구분", "현금영수증번호"
          , "배송현황", "택배사", "송장번호"]
      ]
      const workBook = Xlsx.utils.book_new()
      // const workHeader = Xlsx.utils.sheet_add_aoa(workBook, Heading);
      // Xlsx.utils.book_append_sheet(workBook, workHeader, 'header')
      // const workSheet = Xlsx.utils.sheet_add_json(workBook, this.rentalExcelList, {origin: 'A2', skipHeader: true});

      // this.rentalExcelList.unshift(Heading);
      const workSheet = Xlsx.utils.json_to_sheet(this.rentalExcelList, {origin: 'A2', skipHeader: true});


      Xlsx.utils.sheet_add_aoa(workSheet, Heading);
      // const workSheet = Xlsx.utils.shh(this.rentalExcelList, heading);
      // Xlsx.utils.sheet_add_json(workBook, this.rentalExcelList, {origin: 'A2', skipHeader: true})

      Xlsx.utils.book_append_sheet(workBook, workSheet);
      Xlsx.writeFile(workBook, '렌탈목록.xlsx')
    },
    changeStatus(val) {

      console.log(['changeStatus', val, this.selectedCodes]);
      let param = {
        change_type : val,
        selected_codes : this.selectedCodes
      }

      if (val === 1) {
        param.contract_status = this.selectedOption;
      } else {
        param.delivery_status = this.selectedDelivery;
      }

      nodeApi.putRentals(param).then( res => {
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, "success", 2000);

          this.$router.go(this.$router.currentRoute)
        } else {
          nodeApi.showToast(res.data.msg);
        }
      })



    }
  }
};
</script>

<style>
table.b-table thead th.w10{ width: 10%; }
table.b-table thead th.w20{ width: 20%; }
table.b-table thead th.w30{ width: 30%; min-width: 80px; }
table.b-table thead th.w40{ width: 40%; }
table.b-table thead th.w50{ width: 50%; }
table.b-table thead th.w60{ width: 60%; }
table.b-table thead th.w70{ width: 70%; }
table.b-table thead th.w80{ width: 80%; }
table.b-table thead th.w90{ width: 90%; }
table.b-table thead th.w5{ width: 15%; }
table.b-table thead th.w15{ width: 15%; }
table.b-table thead th.w25{ width: 25%; }
table.b-table thead th.w35{ width: 35%; }
table.b-table thead th.w45{ width: 45%; }
table.b-table thead th.w55{ width: 55%; }
table.b-table thead th.w65{ width: 65%; }
table.b-table thead th.w75{ width: 75%; }
table.b-table thead th.w85{ width: 85%; }
table.b-table thead th.w95{ width: 95%; }

</style>
