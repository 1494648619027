import nodeApi from "@/api/nodeApi";
import Vue from "vue";

const state = {
    statistics_list: []
}
const getters = {
    getStatisticsList(state) {
        return state.statistics_list;
    }
}
const actions = {
    getStatisticsList(context) {
        nodeApi.postStatisticsList({}).then( res => {
            if (res.data.success) {
                context.commit('getStatisticsList', res.data.statistics_list)
            }
        }).catch( err => {
            Vue.$log.error(['get statistics list error', err])
        });
    }
}
const mutations = {
    getStatisticsList(state, payload) {
        state.statistics_list = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
