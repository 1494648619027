import axios from "axios";
import nodeApi from "@/api/nodeApi";
import Vue from "vue";

const state = {
    goodsList: []
}
const getters = {
    getGoodsList: (state) => {
        return state.goodsList;
    }
}
const actions = {
    goodsList: (context) => {
       console.log(['goodsList']);
        nodeApi.postGoodsList({}).then( res => {
            if (res.data.success) {
                context.commit('goodsList', res.data.goods_list)
            }
        }).catch( err => {
            Vue.$log.error(['get rental list error', err])
        });
    },
    getProducts: (context) => {
        const url = "digitalProduct.json";
        ax.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                context.commit('getProducts', response.data);
            })
            .catch(error => {
                console.log('error', error);
            })
    }

}
const mutations = {
    goodsList: (state, payload) => {
        state.goodsList = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
