import axios from 'axios';
import config from '../../../config.json'
import nodeApi from "@/api/nodeApi";
const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'user.json';

const state = {
    userList: []
}
const getters = {
    getUsers(state) {
        return state.userList;
    }
}
const actions = {
    getUsers(context) {
        nodeApi.postUserList({}).then( res => {
            if (res.data.success) {
                context.commit('getUsers', res.data.user_list)
            }
        }).catch( err => {
            console.error(['err', err]);
        });
    }
}
const mutations = {
    getUsers(state, payload) {
        state.userList = payload;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
